<template>
  <div>我是pagetwo页面</div>
</template>

<script>
export default {
    name: 'pageTwo',
    data() {
        return{}
    }
}
</script>
<style scoped>
</style>